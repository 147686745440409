.login__modal {
  bottom: 5px;
  left: 5px;
}

.login__modal__btn {
  background-color: #fff !important;
  border: 0 !important;
  border-radius: 5px;
  color: #111 !important;
  cursor: pointer;
  font-size: 1.5rem;
  font-weight: 700;
  padding: 0.5rem 1rem;
  text-transform: uppercase;
}

.modal__header_text {
  text-align: center !important;
  font-size: 2rem !important;
  padding: 0 !important;
  display: flex;
  justify-content: center !important;
}

.login__modal__container {
  height: 100vh;
}
