.iconbox p {
  font-size: 18px;
  color: #fff;
  text-align: center;
  font-family: monospace;
  font-weight: 900;
  text-transform: uppercase;
}
.skills-header {
  color: #fff;
  text-align: center;
  font-family: monospace;
  text-transform: uppercase;
  font-size: 50px;
  font-weight: 900;
}
.skills-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.skills {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  overflow: hidden;
  position: relative;
  padding: 20px 0;
}

.skills-track {
  display: flex;
  animation: scroll 30s linear infinite;
  white-space: nowrap;
}

.skills-track:hover {
  animation-play-state: paused;
}

@keyframes scroll {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-50%);
  }
}

.skill-icon {
  color: #fff;
  font-size: 100px;
  text-align: center;
}

@media (max-width: 768px) {
  .skills-header {
    font-size: 30px;
  }
  .skill-icon {
    font-size: 50px;
  }
  .iconbox p {
    font-size: 12px;
  }
}

.iconbox {
  padding: 10px 20px;
  flex-shrink: 0;
}
