@import url("https://fonts.googleapis.com/css2?family=Abril+Fatface&family=Acme&family=Almarai:wght@300;400;700;800&family=Amatic+SC:wght@400;700&family=Cairo:wght@200;300;400;500;600;700;800;900;1000&family=Chakra+Petch:ital,wght@0,300;0,500;0,700;1,400&family=El+Messiri:wght@400;500;600;700&family=Finlandica:ital,wght@0,400;0,600;0,700;1,500&family=Kufam:ital,wght@0,500;0,700;1,400;1,500;1,600;1,700;1,800&family=Nanum+Myeongjo:wght@400;700;800&family=Poppins:ital@0;1&family=Readex+Pro:wght@200;300;400;500;600;700&family=Tajawal:wght@400;500;700;800&family=Titillium+Web:ital,wght@0,200;0,400;1,200;1,300&display=swap");

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Cairo", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

:root {
  --primary-color: #0a0a0a;
  --secondary-color: #1a1a1a;
  --tertiary-color: #2a2a2a;
  --quaternary-color: #ffffff;
  --accent-color: #007bff;
  --danger-color: #dc3545;
  --warning-color: #ffc107;
  --text-color: #ffffff;
  --text-secondary: #a0a0a0;
  --transition: all 0.3s ease-in-out;
}

body {
  background-color: var(--primary-color);
  color: var(--text-color);
  line-height: 1.6;
  font-size: 16px;
}

.btn {
  font-size: 1.5rem !important;
  color: var(--text-color) !important;
}
.button1 {
  color: var(--text-color);
  margin: 1rem 0;
  padding: 0.75rem 1.5rem;
  font-size: 1.1rem;
  border-radius: 8px;
  border: 1px solid var(--quaternary-color);
  background-color: rgba(255, 255, 255, 0.1);
  backdrop-filter: blur(5px);
  transition: var(--transition);
  cursor: pointer;
}

@media (max-width: 768px) {
  .button1 {
    margin: 0;
  }
}

.button1:hover {
  background-color: var(--quaternary-color);
  color: var(--primary-color);
  transform: translateY(-2px);
}

.button1:active,
.button1:focus {
  outline: none !important;
  box-shadow: none !important;
}

.project-header {
  color: #453c67;
  text-align: center;
  font-family: monospace;
  text-transform: uppercase;
  font-size: 50px;
  font-weight: 900;
}

.lazy-load-image-loaded {
  width: 100%;
}

input[type="text"],
input[type="email"],
input[type="password"] {
  width: 100%;
  padding: 0.75rem 1rem;
  border-radius: 8px;
  border: 1px solid var(--tertiary-color);
  background-color: rgba(255, 255, 255, 0.05) !important;
  backdrop-filter: blur(5px);
  color: var(--text-color);
  font-size: 1rem;
  margin: 1rem 0;
  transition: var(--transition);
}

input[type="text"]:focus,
input[type="email"]:focus,
input[type="password"]:focus {
  border-color: var(--accent-color) !important;
  box-shadow: 0 0 0 2px rgba(0, 123, 255, 0.25) !important;
}

.btn-close {
  z-index: 1;
  padding: 1.25rem 1rem;
  background-color: #fff !important;
  font-size: 1.5rem;
  text-shadow: 0 1px 0 #fff;
  opacity: 0.5;
}

.btn-close:hover,
.btn-close:focus {
  background-color: #fff !important;
}

@media (min-width: 992px) {
  .custom-offcanvas {
    width: 550px !important;
  }
}

.header-text {
  padding: 2.5rem;
  border-radius: 20px;
  background: rgba(255, 255, 255, 0.03);
  backdrop-filter: blur(15px);
  box-shadow: 0 8px 32px 0 rgba(0, 0, 0, 0.3);
}

.greeting {
  font-size: 1.5rem;
  margin-bottom: 1rem;
  color: var(--highlight-color);
}

.name {
  font-size: 2.5rem;
  margin-bottom: 1.5rem;
}

.highlight {
  background: linear-gradient(
    120deg,
    var(--accent-color) 0%,
    var(--secondary-color) 100%
  );
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
  padding: 0 0.5rem;
  position: relative;
}

.bio p {
  line-height: 1.8;
  margin-bottom: 1rem;
  font-size: 1.1rem;
}

.download-cv {
  background: var(--highlight-color);
  color: white;
  padding: 0.8rem 1.5rem;
  border-radius: 30px;
  transition: var(--transition);
}

.download-cv:hover {
  transform: translateY(-2px);
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.2);
}

.header-img {
  position: relative;
  border-radius: 20px;
  overflow: hidden;
  transition: var(--transition);
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.3);
}

.header-img.hover-effect:hover {
  transform: translateY(-5px);
}

.profile-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: var(--transition);
}

.header-img:hover .profile-image {
  transform: scale(1.08);
}
