:root {
  --card-border-radius: 20px;
  --card-padding: 20px;
  --card-title-font-size: 24px;
  --card-title-font-weight: 700;
  --card-title-color: #fff;
  --card-text-font-size: 17px;
  --card-text-margin: 0 8px 0 0;
  --card-buttons-gap: 12px;
  --card-buttons-border-radius: 25px;
  --card-buttons-padding: 8px 16px;
  --card-buttons-min-width: 100px;
  --card-buttons-font-size: 2.8rem;
  --card-buttons-hover-transform: translateY(-2px);
}

.row {
  padding: 0 0 !important;
  margin: 0 0 !important;
}

.card {
  margin: 0 0 30px 0;
  background: linear-gradient(
    145deg,
    var(--secondary-color),
    var(--primary-color)
  );
  border: none;
  text-transform: capitalize;
  border-radius: var(--card-border-radius);
  transition: all 0.4s cubic-bezier(0.175, 0.885, 0.32, 1.275);
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
  height: 100%;
  display: flex;
  flex-direction: column;
}

.card .img-container {
  overflow: hidden;
  border-radius: var(--card-border-radius);
}

.card .card-img-top {
  width: 100%;
  height: 250px;
  object-fit: cover;
  border-radius: 15px;
  padding: 15px;
  transition: transform 0.5s ease;
}

.card:hover {
  transform: translateY(-10px);
  box-shadow: 0 15px 30px rgba(0, 0, 0, 0.3);
}

.card:hover .card-img-top {
  transform: scale(1.05);
}

.card .card-body {
  padding: var(--card-padding);
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.card .card-title {
  font-size: var(--card-title-font-size);
  font-weight: var(--card-title-font-weight);
  color: var(--card-title-color);
  text-align: center;
  margin-bottom: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.card .card-text {
  display: flex;
  flex-wrap: wrap;
}

.card .card-text span {
  font-size: var(--card-text-font-size);
  margin: var(--card-text-margin);
  background-color: rgba(217, 217, 217, 0.11);
  color: white;
}

.card-buttons {
  display: flex;
  gap: var(--card-buttons-gap);
  flex-wrap: wrap;
  align-content: flex-end;
  justify-content: space-between;
  align-items: center;
}

.card-buttons a {
  flex: 1;
  min-width: var(--card-buttons-min-width);
  text-align: center;
  padding: var(--card-buttons-padding);
  border-radius: var(--card-buttons-border-radius);
  border: 2px solid var(--warning-color);
  transition: all 0.3s ease;
}

.card-buttons a:hover {
  background-color: var(--warning-color);
  color: var(--secondary-color);
  transform: var(--card-buttons-hover-transform);
}

.card-buttons a:focus {
  outline: none;
  box-shadow: none;
}

.card-buttons a i,
.card-buttons a svg {
  font-size: var(--card-buttons-font-size);
  margin-right: 8px;
  color: var(--warning-color);
  transition: all 0.3s ease;
}

.card-buttons a:hover i,
.card-buttons a:hover svg {
  color: var(--secondary-color);
}

.projects {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: 25px;
  padding: 20px;
}

@media (max-width: 992px) {
  .projects {
    grid-template-columns: repeat(auto-fill, minmax(280px, 1fr));
  }
}

@media (max-width: 768px) {
  .projects {
    grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
    padding: 10px;
  }

  .card-buttons {
    justify-content: center;
  }

  .card-buttons a {
    min-width: 80px;
    padding: 8px 12px;
  }
}

@media (max-width: 546px) {
  .card-buttons a {
    min-width: 60px;
    padding: 8px;
  }

  .card-buttons a i,
  .card-buttons a svg {
    font-size: 1.5rem !important;
    margin: 0;
  }

  .card-buttons a span {
    display: none !important;
  }
} /*# sourceMappingURL=style.css.map */
