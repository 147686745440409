.row {
  padding: 0px 0px 0px 0px;
  margin: 0px 0px 0px 0px;
}

.footer__Sosials {
  display: flex;
  justify-self: center;
  align-items: center;
  margin: 4% 0;
}

.footer__Sosials__imag {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 20px;
}
.footer__Sosials__imag img {
  width: 250px;
  height: 250px;
  border-radius: 50%;
  border: 2px solid;
  padding: 8px;
}

@media (max-width: 768px) {
  .footer__Sosials__imag img {
    width: 150px;
    height: 150px;
  }
}

.footer__Sosials__content {
  width: 100%;
  text-align: center;
  text-transform: uppercase;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-flow: column;
}
.footer__Sosials__content .list-unstyled {
  display: flex;
  flex-flow: row;
  justify-self: center;
}
.footer__Sosials__content .list-unstyled li {
  padding: 0 4px;
}
.footer__Sosials__content .list-unstyled li a {
  color: #fff;
  font-size: 20px;
  margin: 0px 10px 0px 0px;
  font-size: 27px;
}
.footer__Sosials__content .list-unstyled li a:hover {
  color: #fff;
}
.footer__Sosials__content h2 {
  margin: 10px 0 5px 0;
}

.footer__Sosials__content h6 {
  margin: 10px 0 5px 0;
  font-size: 1.4em !important;
}

.footer__Sosials__icons {
  display: flex;
  justify-content: center;
  gap: 20px;
  margin-top: 20px;
}

.footer__Sosials__icons a {
  font-size: 32px;
  color: var(--text-color);
  transition: all 0.3s ease;
}

.footer__Sosials__icons a:hover {
  color: var(--primary-color);
  transform: scale(1.2);
}

.footer__Sosials__icons svg {
  width: 30px;
  height: 30px;
  color: white;
}

.footer__Sosials__content {
  text-align: center;
  padding: 40px 0;
}

.footer__Sosials__imag img {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  margin-bottom: 15px;
  object-fit: cover;
}

.footer__Sosials__content h2 {
  font-size: 24px;
  margin-bottom: 10px;
}

.footer__Sosials__content h6 {
  font-size: 16px;
  color: var(--text-color-light);
  margin-bottom: 20px;
}

.social-icon {
  color: var(--text-color);
  font-size: 1.5rem;
  margin: 0 10px;
  transition: color 0.3s ease;
}

.social-icon:hover {
  color: var(--primary-color);
} /*# sourceMappingURL=style.css.map */
