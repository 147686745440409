.row {
  padding: 0 0;
  margin: 0 0;
}

* {
  box-sizing: border-box;
}

.header {
  height: 85vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
}

.header > div {
  width: 40%;
}

@media screen and (max-width: 830px) {
  .header > div {
    width: 100%;
  }
}

.header-img-contant {
  display: flex;
  justify-content: end;
}
.header .header-img {
  width: 65%;
  -webkit-mask-image: url(data:image/svg+xml;base64,PCEtLT94bWwgdmVyc2lvbj0iMS4wIiBzdGFuZGFsb25lPSJubyI/LS0+CiAgICAgICAgICAgICAgPHN2ZyBpZD0ic3ctanMtYmxvYi1zdmciIHZpZXdCb3g9IjAgMCAxMDAgMTAwIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZlcnNpb249IjEuMSI+CiAgICAgICAgICAgICAgICAgICAgPGRlZnM+IAogICAgICAgICAgICAgICAgICAgICAgICA8bGluZWFyR3JhZGllbnQgaWQ9InN3LWdyYWRpZW50IiB4MT0iMCIgeDI9IjEiIHkxPSIxIiB5Mj0iMCI+CiAgICAgICAgICAgICAgICAgICAgICAgICAgICA8c3RvcCBpZD0ic3RvcDEiIHN0b3AtY29sb3I9InJnYmEoNjEsIDYwLCA2NiwgMSkiIG9mZnNldD0iMCUiIGRhdGEtZGFya3JlYWRlci1pbmxpbmUtc3RvcGNvbG9yPSIiIHN0eWxlPSItLWRhcmtyZWFkZXItaW5saW5lLXN0b3Bjb2xvcjojYTkzYTA2OyI+PC9zdG9wPgogICAgICAgICAgICAgICAgICAgICAgICAgICAgPHN0b3AgaWQ9InN0b3AyIiBzdG9wLWNvbG9yPSJyZ2JhKDYxLCA2MCwgNjYsIDEpIiBvZmZzZXQ9IjEwMCUiIGRhdGEtZGFya3JlYWRlci1pbmxpbmUtc3RvcGNvbG9yPSIiIHN0eWxlPSItLWRhcmtyZWFkZXItaW5saW5lLXN0b3Bjb2xvcjojYjk3NDAzOyI+PC9zdG9wPgogICAgICAgICAgICAgICAgICAgICAgICA8L2xpbmVhckdyYWRpZW50PgogICAgICAgICAgICAgICAgICAgIDwvZGVmcz4KICAgICAgICAgICAgICAgIDxwYXRoIGZpbGw9InJnYmEoNjEsIDYwLCA2NiwgMSkiIGQ9Ik0xOS40LC0zMy44QzI2LjEsLTI5LjYsMzMuMywtMjYuNSwzOC40LC0yMS4xQzQzLjQsLTE1LjYsNDYuMywtNy44LDQ2LjQsMC4xQzQ2LjYsNy45LDQzLjksMTUuOSwzOS4zLDIyQzM0LjYsMjguMiwyOCwzMi41LDIxLjEsMzYuN0MxNC4yLDQwLjksNy4xLDQ1LDAuMSw0NC44Qy02LjksNDQuNiwtMTMuOCw0MC4yLC0xOS4yLDM1LjFDLTI0LjYsMzAuMSwtMjguNiwyNC41LC0zMi41LDE4LjVDLTM2LjQsMTIuNSwtNDAuMiw2LjMsLTQxLjgsLTAuOUMtNDMuNCwtOC4yLC00Mi45LC0xNi4zLC0zOC45LC0yMkMtMzQuOCwtMjcuNywtMjcuMiwtMzAuOSwtMjAuMiwtMzQuOUMtMTMuMSwtMzguOSwtNi42LC00My44LC0wLjEsLTQzLjZDNi4zLC00My40LDEyLjYsLTM4LjEsMTkuNCwtMzMuOFoiIHdpZHRoPSIxMDAlIiBoZWlnaHQ9IjEwMCUiIHRyYW5zZm9ybT0idHJhbnNsYXRlKDUwIDUwKSIgc3Ryb2tlLXdpZHRoPSIwIiBzdHlsZT0idHJhbnNpdGlvbjogYWxsIDAuM3MgZWFzZSAwczsiIHN0cm9rZT0idXJsKCNzdy1ncmFkaWVudCkiPjwvcGF0aD4KICAgICAgICAgICAgICA8L3N2Zz4=);
  -webkit-mask-repeat: no-repeat;
}
.header .header-img img {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
  object-fit: cover;
}
.header .header-text {
  color: #fff;
  text-transform: capitalize;
}
.header .header-text h4 {
  font-size: 38px;
  font-weight: 600;
}
.header .header-text p {
  font-size: 18px;
  line-height: 30px;
}
.header .header-text h2 {
  margin: 14px 0px 5px 0;
  font-size: 2.5rem;
}
@media screen and (max-width: 768px) {
  .header .header-text p {
    width: 100%;
  }
}
.header .header-text .header-buttons button:first-child,
.header .header-text .header-buttons button a {
  color: #fff;
  border: 1px #fff solid;
  font-size: 2.2rem;
  outline: none;
  font-weight: 600;
}
.header .header-text .header-buttons button:first-child:hover,
.header .header-text .header-buttons button a:hover {
  color: #453c67;
  background-color: #fff;
  outline: none !important;
}
.header .header-text .header-buttons button:first-child:focus,
.header .header-text .header-buttons button a:focus {
  border: 1px #fff solid !important;
  outline: none !important;
}

@media screen and (max-width: 830px) {
  .header .header-text .header-buttons {
    display: flex;
    justify-content: center !important;
    align-items: center;
  }
  .header .header-text .header-buttons button:first-child {
    margin: 0 10px 0 0;
  }
}
.header .header-text .list-unstyled {
  margin: 19px 0 0 0;
  display: flex;
  justify-self: center;
  align-items: center;
}
.header .header-text .list-unstyled li {
  display: inline;
  padding: 0 4px;
}
.header .header-text .list-unstyled li a {
  color: #fff;
  font-size: 20px;
  margin: 0px 10px 0px 0px;
  font-size: 27px;
}
.header .header-text .list-unstyled li a:hover {
  color: #ffb84c !important;
  transition: all 0.3s ease-in-out;
}

@media screen and (max-width: 830px) {
  .header {
    height: auto;
    flex-direction: column-reverse;
    padding: 50px 0;
  }
  .header .header-text {
    width: 100%;
    text-align: center;
    flex: 100% !important;
  }
  .header .header-text h4 {
    font-size: 30px;
  }
  .header .header-text p {
    width: 100%;
    font-size: 16px;
    line-height: 25px;
    padding: 0 10px;
  }

  @media screen and (max-width: 830px) {
    .header .header-text p {
      padding: 0 15px;
      line-height: 30px;
    }
  }
  .header .header-img {
    width: 70%;
    height: 70%;
    -o-object-fit: cover;
    object-fit: cover;
  }
  .header .header-img-contant {
    width: 100%;
    justify-content: center;
  }
  .project-header {
    margin: 100px 0 0 0;
    color: #fff;
  }
  .header-social {
    display: flex;
    justify-content: center;
  }
} /*# sourceMappingURL=style.css.map */
