.gproject_card_container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  align-items: center;
  margin: 0 auto;
  padding: 50px 0;
}

.gproject_card_container .gproject_card {
  min-height: 200px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  padding: 35px;

  width: 80%;
  border: 1px solid rgba(255, 255, 255, 0.25);
  border-radius: 20px;
  background-color: rgba(255, 255, 255, 0.45);
  box-shadow: 0 0 10px 1px rgba(0, 0, 0, 0.25);

  backdrop-filter: blur(15px);

  transition: transform 0.3s ease-in-out;
}

@media (max-width: 768px) {
  .gproject_card_container .gproject_card {
    width: 95%;
    padding: 20px;
  }
}

.gproject_card h3 {
  font-size: 3.5rem;
  font-weight: 600;
  color: #fff;
  margin-bottom: 20px;
  text-align: center;
}
.gproject_card_container .gproject_card_body {
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-wrap: wrap;
}

.gproject_card_container .gproject_card_body > div {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  width: 47%;
}
@media (max-width: 768px) {
  .gproject_card_container .gproject_card_body > div {
    width: 100%;
    margin-bottom: 20px;
  }
}
.gproject_card_container .gproject_card_body > div:first-child > iframe {
  height: 300px;
  border: none;
  border-radius: 20px;
}

.gproject_card_container .gproject_card_body .gproject_card_content {
  color: #fff;
  font-size: 1.6rem;
  padding: 18px;
  border-radius: 12px;
  font-weight: 400;
  line-height: 27px;
}

@media (max-width: 768px) {
  .gproject_card_container .gproject_card_body .gproject_card_content {
    font-size: 1.2rem;
    line-height: 20px;
  }
}

.gproject_card:hover {
  transform: translateY(-5px);
}

.btn {
  transition: all 0.3s ease;
}

.btn:hover {
  transform: translateY(-2px);
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.2);
}

.gproject_card h3 svg {
  transition: transform 0.3s ease;
}

.gproject_card:hover h3 svg {
  transform: rotate(45deg);
}
